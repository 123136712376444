import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const AileHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
      <title>HİZMETLERİMİZ | AİLE HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com/ozel-hukuk/aile-hukuku"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="https://www.eksiogluhukukofisi.com/static/media/eksiogluWhiteLogo.e14b5e9c41e5271d670c.png" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com/ozel-hukuk/aile-hukuku"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h1
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>AİLE HUKUKU</b>
            </h1>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Aile Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/aileHukuku.webp")}
                height={540}
                width={540}
                loading="eager"
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Aile hukuku, aile birliği ile bireyler arasındaki ilişkiyi düzenleyen ve çözmeye çalışan bir hukuk dalıdır. Bu alandaki yasalar evlilik, boşanma, mal paylaşımı, nafaka, velayet, evlat edinme ve miras gibi konulara odaklanmaktadır. Dolayısıyla aile hukuku, bireyleri ve aile birliğini korumayı amaçlayan oldukça geniş bir konuya sahiptir.
            </p>
            <br></br>
            <p>
            Aile hukuku alanına bakıldığında evlilik ilişkileri ilk sırada yer almaktadır. İlişkiler, iki kişi arasındaki sosyal bir bağdır ve aile hukukunun temel taşıdır. Bu nedenle aile hukuku evliliklerin korunması ve boşanmasında önemli bir rol oynamaktadır.
            </p>
            <br></br>
            <p>
            Ayrıca boşanma davaları da aile hukukunun kapsamına girmektedir. Boşanma davalarında amaç, çiftlerin evliliğinin bozulmasına bağlı olarak ortaya çıkan hukuki sorunlara çözüm bulmaktır.
            </p>
            <br></br>
            <p>
            Ayrıca mal paylaşımı ve miras konuları da aile hukukunun bir diğer alanıdır. Mal paylaşımı davaları, evlilik sona erdikten sonra bir çiftin mal varlığının nasıl bölüşüleceğine ilişkin hukuki soruları içerirken, veraset davaları, bir kişinin ölümünden sonra mal varlığının nasıl bölüşüleceğine ilişkin hukuki soruları ele alır.
            </p>
            



            </Col>
          </Row>
          <Row>
            
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="12"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            
            <p>
            Diğer önemli aile hukuku konuları velayet, nafaka ve evlat edinmedir. Velayet davaları, bir çiftin ayrılmasının ardından çocukların velayetinin kime ait olduğuna ilişkin hukuki sorunları çözmeyi amaçlamaktadır. Öte yandan diğer davalar, boşanma sonrasında bir tarafın diğerine sürekli olarak mali destek vermesiyle ilgili hukuki meselelerle ilgilidir. Son olarak evlat edinme davaları, başka bir aileden gelen bir çocuğun yasal olarak evlat edinilmesi için gerekli olan yasal prosedürleri içerir.
            </p>



            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
            <AccordionTab header="Boşanma davası nasıl açılır?">
              <p className="m-0">
              Tarafların anlaşmasına göre boşanmanın karşılıklı anlaşmayla mı yoksa anlaşmazlıkla mı gerçekleşeceğine karar verilmesi gerekir. Her iki taraf da boşanmak istiyorsa ve boşanmanın diğer hususları üzerinde anlaşıyorsa, anlaşmalı boşanma mümkündür. Taraflardan yalnızca biri boşanmak istiyorsa veya her iki taraf da boşanmak istiyorsa ancak boşanmanın diğer mali konularında anlaşmazlıklar varsa, anlaşmalı boşanma mümkün değildir ve çekişmeli boşanma davasında rücuya başvurulması zorunludur.
              </p>
            </AccordionTab>
            <AccordionTab header="Boşanma davasına katılmak zorunlu mu?">
              <p className="m-0">
              Dava açan tarafın açtığı boşanma davasında kendisini temsil edecek avukatı yoksa davaya katılması gerekmektedir. Sözlü yargılamaya katılmayan taraf konuyu ele almadığından dosya başvurudan çıkarılır. Sanık duruşmaya katılmamalıdır. Ancak davada kendisini savunabilmesi ve dava açabilmesi için duruşmaya katılması veya tarafların karşılıklı anlaşmasıyla ayrılması halinde kendisini bir avukat tarafından temsil etmesi gerekir. Boşanma anlaşmasında taraflar avukatla temsil edilseler dahi yargılamaya kendilerinin katılması gerekmektedir. Taraflar yargılamaya katılmazlarsa dava takip edilmeyeceği için boşanma da gerçekleşmeyecektir.
              </p>
            </AccordionTab>
            <AccordionTab header="Boşanma Sebepleri Nelerdir?">
              <p className="m-0">
              Türk Medeni Kanunu’nda boşanma sebepleri sınırlı sayıda sayılmıştır. Kanunda bulunmayan bir sebebe dayanarak boşanma davası açılması yasal olarak mümkün değildir. Bu nedenle boşanmak isteyen kişi, neden boşanmak istediğini ve bu boşanma sebebinin kanunen hangi boşanma sebebine girdiğini bilerek ve dilekçesinde bunları gerekçeli şekilde belirterek dava açmalıdır. Türk Medeni Kanunu’na göre boşanma sebepleri:<br/><br/>
                  -Evlilik birliğinin temelden sarsılması (şiddetli geçimsizlik),<br/>
                  -Zina (aldatma),<br/>
                  -Akıl hastalığı,<br/>
                  -Terk,<br/>
                  -Hayata kast, pek kötü veya onur kırıcı davranış,<br/>
                  -Suç işleme ve haysiyetsiz hayat sürme.
              </p>
            </AccordionTab>
            {/* <AccordionTab header="Aile Hukuku nedir?">
              <p className="m-0">
                Aile hukuku, aile içi ilişkileri ve bu ilişkilerden doğan hukuki sonuçları düzenleyen bir hukuk dalıdır. Bu alan, evlilik, boşanma, velayet, mal rejimi, nafaka ve soybağı gibi konuları kapsar.
              </p>
            </AccordionTab>
            <AccordionTab header="Aile Hukuku kimleri kapsar?">
              <p className="m-0">
                Aile hukuku, evli çiftler, boşanmış eşler, çocuklar, ebeveynler ve diğer aile bireyleri arasındaki hukuki ilişkileri kapsar. Aile içi anlaşmazlıkların çözümünde ve aile bireylerinin haklarının korunmasında önemli bir rol oynar.
              </p>
            </AccordionTab>
            <AccordionTab header="Aile Hukuku neden önemlidir?">
              <p className="m-0">
                Aile hukuku, aile bireylerinin hak ve yükümlülüklerini düzenler ve korur. Aile içi anlaşmazlıkların çözümü, çocukların haklarının korunması ve aile içi huzurun sağlanması açısından kritik öneme sahiptir.
              </p>
            </AccordionTab>
            <AccordionTab header="Boşanma davalarında neler yapılabilir?">
              <p className="m-0">
                Boşanma davalarında, evliliğin sona erdirilmesi, nafaka talepleri, velayet ve mal paylaşımı konuları ele alınır. Ekşioğlu Hukuk Bürosu, müvekkillerine boşanma sürecinde hukuki destek sağlamaktadır.
              </p>
            </AccordionTab>
            <AccordionTab header="Velayet davalarında ne yapılabilir?">
              <p className="m-0">
                Velayet davalarında, çocuğun hangi ebeveynle kalacağı ve ebeveynlerin çocuğa dair hak ve sorumlulukları belirlenir. Çocuğun en iyi menfaatlerinin gözetilmesi esastır ve Ekşioğlu Hukuk Bürosu bu süreçte müvekkillerine profesyonel hukuki destek sunar.
              </p>
            </AccordionTab>
            <AccordionTab header="Nafaka nedir ve nasıl talep edilir?">
              <p className="m-0">
                Nafaka, boşanma sonrası bir eşin diğerine veya çocuklarına maddi destek sağlamasıdır. Nafaka talebi, mahkemeye başvurarak yapılır ve nafakanın miktarı mahkeme tarafından belirlenir. Ekşioğlu Hukuk Bürosu, nafaka talepleri konusunda hukuki danışmanlık ve temsil hizmeti sunmaktadır.
              </p>
            </AccordionTab>
            <AccordionTab header="Aile içi şiddet durumunda neler yapılabilir?">
              <p className="m-0">
                Aile içi şiddet durumunda, şiddet mağdurları korunma talepleriyle mahkemeye başvurabilir ve koruyucu tedbirler talep edebilir. Ekşioğlu Hukuk Bürosu, aile içi şiddet durumlarında hukuki destek ve danışmanlık hizmeti sunar.
              </p>
            </AccordionTab>
            <AccordionTab header="Aile hukuku danışmanlık süreci nasıl işler?">
              <p className="m-0">
                Aile hukuku danışmanlık süreci, müvekkilin ihtiyaç ve durumunun değerlendirilmesiyle başlar. Ardından, hukuki gereklilikler doğrultusunda danışmanlık hizmeti sunulur ve gerekli hukuki adımlar atılır. Ekşioğlu Hukuk Bürosu, bu sürecin her aşamasında profesyonel destek sağlar.
              </p>
            </AccordionTab>
            <AccordionTab header="Ekşioğlu Hukuk Bürosu'na kimler başvurabilir?">
              <p className="m-0">
                Ekşioğlu Hukuk Bürosu'na, aile hukuku alanında hukuki danışmanlık ve destek almak isteyen herkes başvurabilir. Evli çiftler, boşanmış eşler, ebeveynler ve diğer aile bireyleri hukuki hizmetlerden faydalanabilirler.
              </p>
            </AccordionTab> */}
          </Accordion>



          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default AileHukuku;
