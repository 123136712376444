import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const VergiHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | VERGİ HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com/kamu-hukuku/vergi-hukuku"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="https://www.eksiogluhukukofisi.com/static/media/eksiogluWhiteLogo.e14b5e9c41e5271d670c.png" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com/kamu-hukuku/vergi-hukuku"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h1
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>VERGİ HUKUKU</b>
            </h1>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.webp")}
                height={540}
                width={540}
                loading="eager"
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Vergi hukuku, vergi ödevinin niteliğine, vergi borcunun doğması ve ortadan kalkmasına ilişkin maddi ve şekli hukuk kuralları bütünüdür. Vergi hukuku konuları itibariyle alt dallara bölünür: Vergi usul hukuku, vergi icra hukuku, vergi yargılama hukuku ve vergi ceza hukuku, bu dersin konusunu oluşturan genel vergi hukukunun alt dallarıdır.
            </p>
            <br></br>
            <p>
            Ülkemiz vergi sisteminde, mevzuatın son derece karmaşık olması ve mevzuatta bulunan hükümlerin sıkça değişmesi nedeniyle, müvekkillerimize;<br/><br/>

              -Vergi Hukukuna ait mevzuatta öngörülen idari çözüm yolları (uzlaşma, cezalarda indirim, pişmanlık gibi)<br/>

              -Gerektiğinde yargı yoluna başvurma aşamalarında (Vergi cezası, vergi tarhına karşı iptal davası açmak, vergi nedeniyle gönderilen ödeme emrine ve ihtiyati hacze itiraz, sahte fatura düzenleme veya kullanma suçlarından kaynaklanan ceza davalarını takip etmek gibi) konularda danışmanlık hizmeti ve avukatlık hizmeti büromuz tarafından verilmektedir.
            </p>
            
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="Vergi Mahkemeleri Hangi Davalara Bakar?">
        <p className="m-0">
        Vergi mahkemeleri; genel bütçeye, il özel idareleri, belediye ve köylere ait vergi, resim ve harçlar ile benzeri mali yükümler ve bunların zam ve cezaları ile tarifelere ilişkin davaları ve ayrıca bu konularda 6183 sayılı Amme Alacaklarının Tahsil Usulü Hakkında Kanun’un uygulanmasına ilişkin davaları (örneğin vergi borçlarından kaynaklı ödeme emri, haciz, ihtiyati haciz, ihtiyati tahakkuk, teminat istenmesine ilişkin yazıya karşı açılan davalar vs.) ve diğer kanunlarla verilen isleri (davaları) çözümler. 
        </p>
    </AccordionTab>
    <AccordionTab header="Vergi Mahkemelerinde Hangi İdari İslemler Dava Konusu Edilebilir?">
        <p className="m-0">
        Vergi davalarında dava konusu edilen idari işlemler genellikle vergi/ceza ihbarnamesi, ödeme emri, haciz, ihtiyati tahakkuk, ihtiyati haciz, vergi hatası kapsamında yapılan düzeltme-sikayet başvurusunun reddine ilişkin işlem, ihtirazi kayıtla verilen beyanname üzerine yapılan tahakkuk işlemi, gümrük vergilerine ilişkin vergi davalarında ek tahakkuk ve/veya ceza kararına karşı yapılan itirazın reddine ilişkin işlem, geri verme/kaldırma başvurusunun reddedilmesine vaki itirazın reddine ilişkin işlem, gümrük idaresince alınan idari karara vaki itirazın reddine ilişkin işlem, vergi borçlarıyla ilgili uzlasma talebinin reddine ilişkin işlem, vergi iadesinin yapılması istemiyle yapılan başvurunun reddine ilişkin işlem, vergi mükellefiyeti ile ilgili tesis edilen idari işlemler, özel esaslara tabi mükellefler listesine veya KDV iadeleri yönünden özel esaslara tabi mükellefler listesine alınmasına ilişkin işlem gibi idari işlemlerdir. Bu idari işlemler vergi daireleri, Gelir Idaresi Baskanlıgı, gümrük idareleri ve belediyeler tarafından tesis edilir. 
        </p>
    </AccordionTab>
    <AccordionTab header="Vergi Davalarında Yetkili Mahkeme Nasıl Belirlenir?">
        <p className="m-0">
        Genelde vergi uyusmazlıklarında yetkili mahkeme, dava konusu edilen idari işlemi tesis eden idarenin bulunduğu yerdeki vergi mahkemesidir. Buna karşın düzeltme-sikayet başvurusunun reddine ilişkin işlem dava konusu ediliyorsa, bu davalarda yetkili mahkeme; dava konusu edilen sikayet başvurusunun reddine ilişkin işlemi tesis eden idarenin (Gelir Idaresi Baskanlıgı) bulunduğu yer vergi mahkemesi olmayıp; vergi, resim, harç ve benzeri mali yükümleri tarh ve tahakkuk ettiren idarenin bulunduğu yerdeki vergi mahkemesidir. 
        </p>
    </AccordionTab>
    <AccordionTab header="Vergi Mahkemelerinde Dava Açma Süresi Hangi Tarihte Başlar?">
        <p className="m-0">
        Vergi mahkemelerinde dava açma süresi; vergi, resim ve harçlar ile benzeri mali yükümler ve bunların zam ve cezalarından dogan uyusmazlıklarda; tahakkuku tahsile baglı vergilerde (örneğin damga vergisi, tapu harcı vs.) tahsilatın; tebliğ yapılan hallerde veya tebliğ yerine geçen işlemlerde (örneğin ihbarname, 2 nolu ihbarname, ödeme emri, haciz tutanağı, ihtirazi kayıtla verilen beyanname üzerine düzenlenen tahakkuk fisi, ihtiyati haciz ve/veya ihtiyati tahakkuk kararı alındığını bildiren işlem, teminat isteme yazısı, şikayet başvurusunun reddine ilişkin işlem, ek tahakkuk ve/veya ceza kararına vaki itirazın reddine ilişkin işlem, gümrük vergileriyle ilgili olarak yapılan geri verme/kaldırma başvurusunun reddine vaki itirazın reddine ilişkin işlem, 6183 sayılı Kanun’un 37.maddesi (vade belirleme) veya 56.maddesi (teminatın paraya çevrilmesi öncesinde tesis edilen işlem) kapsamında tesis edilen idari işlem, vergi iadesinin yapılması istemiyle yapılan başvurunun reddine ilişkin işlem vs.) tebliğin; tevkif yoluyla alınan vergilerde (örneğin gelir stopaj vergisi vs.) istihkak sahiplerine ödemenin; tescile baglı vergilerde tescilin yapıldıgı tarihi izleyen günden başlar. 
        </p>
    </AccordionTab>
    <AccordionTab header="Vergi Davalarında Kaç Gün İçerisinde Dava Açılmalıdır?">
        <p className="m-0">
        Vergi mahkemelerinde dava açma süresi, özel kanunlarında ayrı süre gösterilmeyen hallerde otuz gündür. Ancak kendi kanunlarında belirlenmis özel bir dava açma süresi öngörülmüsse bu süre içerisinde vergi davasının açılması gerekir. Örneğin 6183 sayılı Kanun’a göre ödeme emrine karşı ödeme emrinin tebliğinden itibaren on bes gün içerisinde, ihtiyati hacze karşı ihtiyati haciz kararının tebliğinden itibaren on bes gün içerisinde, ihtiyati tahakkuka karşı ihtiyati tahakkuk kararının tebliğinden itibaren on bes gün içerisinde dava açılması gerekir. 
        </p>
    </AccordionTab>
    <AccordionTab header="Vergi Davasında Duruşma Nasıl Yapılır?">
        <p className="m-0">
        Duruşmalar genel olarak açık şekilde yapılır. Duruşmayı vergi mahkemesi başkanı yönetir. Duruşmada taraflara ikiser defa söz verilir. Yalnızca davacı veya yalnızca davalı idare gelirse, yani taraflardan yalnızca biri gelirse, gelen tarafın açıklamaları dinlenir. Duruşma tamamlandıktan sonra vergi mahkemesince duruşma tutanağı düzenlenir ve duruşmaya katılan hakimlerce duruşma tutanağı imzalanır. Duruşma tutanağında, duruşmaya katılan kişilere ilişkin bilgilere ve kısaca tarafların duruşmada ileri sürdükleri iddia ve yaptıkları savunmalara yer verilir. Duruşma tutanağ dava dosyasına konulur. 
        </p>
    </AccordionTab>
    {/* <AccordionTab header="Vergi Hukuku nedir?">
        <p className="m-0">
            Vergi hukuku, vergi yükümlülüklerini ve vergi idaresinin yetkilerini düzenleyen hukuk dalıdır. 
        </p>
    </AccordionTab>
    <AccordionTab header="Vergi Hukuku'nun temel prensipleri nelerdir?">
        <p className="m-0">
            Vergi hukukunun temel prensipleri kanuniyet, eşitlik, genellik, özel ve örtülü vergiye karşı koruma ve vergi adaletidir.
        </p>
    </AccordionTab>
    <AccordionTab header="Vergi türleri nelerdir?">
        <p className="m-0">
            Vergi türleri arasında gelir vergisi, kurumlar vergisi, katma değer vergisi, emlak vergisi gibi çeşitli vergiler bulunur.
        </p>
    </AccordionTab>
    <AccordionTab header="Vergi cezaları ve yaptırımları nelerdir?">
        <p className="m-0">
            Vergi hukukunda yanlış beyan, vergi ziyaı, gecikme cezası gibi çeşitli cezalar ve yaptırımlar uygulanabilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Vergi incelemesi ve itiraz süreci nasıl işler?">
        <p className="m-0">
            Vergi incelemesi, vergi idaresinin mükelleflerin beyanlarını incelemesi ve varsa eksiklikler veya hatalar üzerine düzeltmeler yapılması sürecidir. Mükellefler, vergi incelemesi sonucunda çıkan kararlara karşı itiraz hakkına sahiptir.
        </p>
    </AccordionTab>
    <AccordionTab header="Vergi hukuku ve iş hukuku ilişkisi nedir?">
        <p className="m-0">
            İş hukuku ve vergi hukuku arasındaki ilişki, çalışanların vergi yükümlülükleri ve işverenlerin vergi mevzuatına uyum sağlama yükümlülükleri üzerinde odaklanır.
        </p>
    </AccordionTab>
    <AccordionTab header="Vergi danışmanlığı hizmetleri neleri kapsar?">
        <p className="m-0">
            Vergi danışmanlığı, mükelleflere vergi planlaması, vergi optimizasyonu, vergi risk yönetimi gibi konularda danışmanlık hizmetleri sunar.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda vergi hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine vergi danışmanlığı, vergi uyuşmazlıklarında hukuki destek, vergi incelemelerine karşı savunma hizmetleri ve vergi planlaması konularında geniş kapsamlı hizmetler sunmaktadır.
        </p>
    </AccordionTab> */}
</Accordion>



          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default VergiHukuku;
