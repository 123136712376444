/*eslint-disable*/
import React from "react";
import 'aos/dist/aos.css';

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// core components

function Footer() {
  
  return (
    <>
      <footer className="footer" style={{ fontFamily: "Nucleo Outline" }}>
        <br/><hr/><br/>
        <Container >
          <div className="content text-center">
            <Row>
              <Col md="2" className="text-md-left text-sm-center">
                <h2 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}><b>Menü</b></h2>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="text-muted"
                      href="/"
                
                    >
                      Ana Sayfa
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/hakkimizda"
                     
                    >
                      Hakkımızda
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/"

                      
                    >
                      Blog Paylaşımları
                    </a>
                  </li>
                  
                  <li>
                    <a
                      className="text-muted"
                      href="/"
                      
                    >
                      Videolar
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/iletisim"
                      
                    >
                      İletişim
                    </a>
                  </li>
                  
                  <li>
                    <a
                      className="text-muted"
                      href="https://www.hasarhukukkurumsal.com/login"
                      
                    >
                      Ekşioğlu Portal
                    </a>
                  </li>
                  
                </ul>
              </Col>
              <Col md="2" className="text-md-left text-sm-center">
                <h2 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}><b>Hizmetlerimiz</b></h2>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="text-muted"
                      href="/ozel-hukuk/sozlesmeler-ve-borclar-hukuku"
                      
                    >
                      Özel Hukuk
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/kamu-hukuku/ceza-hukuku"
                     
                    >
                      Kamu Hukuku
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/ticaret-hukuku/ticaret-hukuku"
                     
                    >
                      Ticaret Hukuku
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/tuketici-ve-saglik-hukuku/tuketici-hukuku"
                     
                    >
                      Tüketici ve Sağlık Hukuku
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-muted"
                      href="/teknoloji-ve-verilerin-korunmasi-hukuku/kisisel-verilerin-korunmasi-hukuku"
                     
                    >
                      Teknoloji ve Verilerin Korunması Hukuku
                    </a>
                  </li>
                  
                </ul>
              </Col>
              <Col md="4" className="text-md-left text-sm-center">
                <h2 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}><b>Adres</b></h2>
                <div className="social-feed">
                  <div className="feed-line">
                    
                    <p>
                    Soğanlık Yeni Mah. Aliağa Sok. No:8 K:30 D:159 Bumerang Towers Kartal/İstanbul
                    </p>
                  </div>
                  <div className="feed-line">
                  <iframe title="Ekşioğlu Hukuk Ofisi Adres" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12059.631297201557!2d29.179745!3d40.9177669!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cad19b832a9b95%3A0x7eed51a12e72685b!2zRWvFn2lvxJ9sdSBIYXNhciBEYW7EscWfbWFubMSxxJ_EsQ!5e0!3m2!1str!2str!4v1703981049032!5m2!1str!2str" width="auto" height="auto" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
              </Col>
              <Col md="4" className="text-md-left text-sm-center">
                <h2 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}><b>Bizi Takip Edin</b></h2>
                <ul className="social-buttons">
                  <li>
                  <Button
                      className="btn-icon btn-neutral mx-3"
                      color="instagram"
                      target="_blank"
                      href="https://www.instagram.com/eksiogluhasardanismanlik/"
                      loading="lazy"
                    >
                      <img src={require("../../assets/ossaPictures/instagram.png")} alt="instagram" loading="lazy" width="100%" height="auto"/>
                      <span style={{ display: "none" }}>
                        Instgram Ekşioğlu Hukuk
                      </span>
                    </Button>
                  </li>
                  
                  <li>
                  <Button
                      className="btn-icon btn-neutral mx-3"
                      color="facebook"
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=61554533796281&mibextid=ZbWKwL"
                      
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="facebook"><path fill="#1976D2" d="M14 0H2C.897 0 0 .897 0 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V2c0-1.103-.897-2-2-2z"></path><path fill="#FAFAFA" fill-rule="evenodd" d="M13.5 8H11V6c0-.552.448-.5 1-.5h1V3h-2a3 3 0 0 0-3 3v2H6v2.5h2V16h3v-5.5h1.5l1-2.5z" clip-rule="evenodd"></path></svg>
                      <span style={{ display: "none" }}>
                        Facebook Ekşioğlu Hukuk
                      </span>
                    </Button>
                  </li>
                </ul>
                <h2 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}>
                  <small><b><u>E-mail</u></b></small>
                </h2>
                <p style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}>
                bilgi@eksiogluhukukofisi.com
                </p>
                <h2 style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}>
                  <small><b><u>Telefon</u></b></small>
                </h2>
                <p style={{ fontSize:"1.3rem",fontWeight:"400",marginTop:"15px",marginBottom:"0.5rem" }}>
                0850 460 95 03
                </p>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Powered by{" "}<a className="text-dark" href="/">Ekşioğlu Hukuk</a>
            
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
