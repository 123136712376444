import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const DenizTicaretiHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HAKKIMIZDA | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com/ekonomi-ve-ticaret-hukuku/deniz-ticareti-hukuku"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="https://www.eksiogluhukukofisi.com/static/media/eksiogluWhiteLogo.e14b5e9c41e5271d670c.png" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com/ekonomi-ve-ticaret-hukuku/deniz-ticareti-hukuku"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h1
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>DENİZ TİCARETİ HUKUKU</b>
            </h1>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.webp")}
                height={540}
                width={540}
                loading="eager"
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
                Deniz ticareti hukuku, denizde gerçekleşen ticari faaliyetleri düzenleyen ve bu faaliyetlerden doğan hukuki sonuçları ele alan hukuk dalıdır. Hukuk büroları, müvekkillerinin deniz ticareti hukukuyla ilgili ihtiyaçlarını karşılamak için sıkça başvurdukları profesyonel kurumlardır. Bu alanda öne çıkan ofislerden biri de, <b>Ekşioğlu Hukuk Bürosu</b>'dur, uzman avukatları ve geniş deneyimiyle bilinmektedir.
            </p>
            <br></br>
            <p>
                <b>Ekşioğlu Hukuk Bürosu</b>, müvekkillerine deniz ticareti hukuku alanında kapsamlı hukuki danışmanlık hizmetleri sunmakta ve uzman avukatlarıyla hizmet vermektedir. Müvekkillerinin deniz ticareti faaliyetlerinde haklarını korumak ve uluslararası deniz hukuku kurallarına uygunluk sağlamak için sağladığı hukuki desteklerle tanınmaktadır.
            </p>
            <br></br>
            <p>
                <b class="emphasize">Deniz Ticareti Hukuku</b>, deniz kazaları, gemi alım-satım işlemleri, deniz taşımacılığı sözleşmeleri gibi konularda hukuki düzenlemeler ve koruma sağlar. <b>Ekşioğlu Hukuk Bürosu</b>, deniz kazalarından sigorta taleplerine, liman hizmetleri sözleşmelerinden denizcilik yasalarına kadar geniş bir yelpazede deniz ticareti hukuku hizmeti sunmakta ve müvekkillerine özel çözümler üretmektedir. Sonuç olarak, <b>Ekşioğlu Hukuk Bürosu</b>, deniz ticareti hukuku alanında uzmanlığı ve kaliteli hizmet anlayışıyla öne çıkmaktadır.
            </p>



            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="Deniz Ticareti Hukuku nedir?">
        <p className="m-0">
            Deniz ticareti hukuku, denizlerde yapılan ticaret faaliyetlerini düzenleyen hukuk dalıdır. Deniz ticaretine ilişkin gemi işletmeciliği, deniz ticaret sözleşmeleri, deniz korsanlığına karşı önlemler gibi konuları kapsar.
        </p>
    </AccordionTab>
    <AccordionTab header="Gemi işletmeciliği ve gemi sicili nasıl düzenlenir?">
        <p className="m-0">
            Gemi işletmeciliği, gemilerin işletilmesi ve yönetilmesini içerir. Gemi sicili ise gemilerin kaydedildiği resmi kayıt sistemidir ve gemi sahipliğinin belgelendirilmesinde kullanılır.
        </p>
    </AccordionTab>
    <AccordionTab header="Deniz ticaret sözleşmeleri ve türleri nelerdir?">
        <p className="m-0">
            Deniz ticaret sözleşmeleri, deniz ticareti faaliyetlerinde yer alan taraflar arasında yapılan sözleşmelerdir. Gemi kira sözleşmeleri, gemi alım-satım sözleşmeleri, yük taşıma sözleşmeleri gibi çeşitli türleri bulunur.
        </p>
    </AccordionTab>
    <AccordionTab header="Deniz kazaları ve sorumlulukları nasıl düzenlenir?">
        <p className="m-0">
            Deniz kazaları durumunda gemi sahiplerinin ve işletmecilerinin sorumlulukları, deniz ticareti hukuku çerçevesinde belirlenir. Kargo hasarı, deniz korsanlığı gibi durumlar da bu kapsamda değerlendirilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Deniz ticaretinde uluslararası hukukun rolü nedir?">
        <p className="m-0">
            Deniz ticaretinde uluslararası hukuk, gemilerin uluslararası sularda seyri, uluslararası anlaşmalar ve sözleşmelerin uygulanması gibi konularda düzenlemeler getirir.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda deniz ticareti hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine gemi işletmeciliği hukuki danışmanlık, deniz ticaret sözleşmeleri hazırlanması, deniz kazaları ve sorumlulukları konusunda hukuki destek sağlamaktadır.
        </p>
    </AccordionTab>
</Accordion>



          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default DenizTicaretiHukuku;
