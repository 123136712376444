import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const KisiselVerileriKorumaKanunu = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | KVKK | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com/teknoloji-ve-internet-hukuku/kisisel-verilerin-korunmasi-hukuku"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="https://www.eksiogluhukukofisi.com/static/media/eksiogluWhiteLogo.e14b5e9c41e5271d670c.png" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com/teknoloji-ve-internet-hukuku/kisisel-verilerin-korunmasi-hukuku"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h1
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>KİŞİŞEL VERİLERİN KORUNMASI HUKUKU</b>
            </h1>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.webp")}
                height={540}
                width={540}
                loading="eager"
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
          <p>
          Kişisel verilerin korunması hukuku özellikle son yıllarda önem kazanmış ve hayatımıza girmiş bir hukuk dalıdır. Bu alanda çıkartılan Kişisel Verilerin Korunması Kanunu ile de kapsam ve alanı belirlenmiştir. Ancak maalesef halen ülkemizde bu hukuk alanına gereken önemin ve değerin verilmediği, birçok hak ihlaline neden olunduğu gözlemlenmektedir. Aslında bu hukuk alanında çok ağır yaptırımlar da ön görülmüştür. 
          </p>
          <br></br>
          <p>
          Ülkemizde kurulan ve faaliyet gösteren Kişisel Verilerin Korunması Kurumu’nun faaliyet alanı bu konulardır. Ancak kişisel bilgilere çok fazla sahip çıkılamadığı, para karşılığı ticari meta haline dönüştürüldüğü de gözlemlenmektedir. Gerek kişisel verilerinizin ele geçmesi durumunda, gerekse de ticari faaliyet alanınızda, bu kanun kapsamında hukuki yaptırımlara uğramamanız adına her birisi alanında tecrübeli avukatlarımız ile sizlere hukuki yardım sunmaktan onur duyarız.
          </p>
          


            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="Aydınlatma metni nedir ve hangi konuları içermelidir?">
        <p className="m-0">
        Günümüzde en çok karşılaşılan konulardan birisi de aydınlatma metinleridir. Hemen hemen hepimiz bir telefon konuşması öncesinde, bu metinlerinden birisini dinlemişizdir. Peki bir aydınlatma metni neleri içermelidir?<br /><br />

        6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 10. maddesi kapsamında kişisel verilerin elde edilmesi sırasında veri sorumlusu veya yetkilendirdiği kişi, ilgili kişilere;<br /><br />

        - Veri sorumlusunun ve varsa temsilcisinin kimliği,<br />
        - Kişisel verilerin hangi amaçla işleneceği,<br />
        - İşlenen kişisel verilerin kimlere ve hangi amaçla aktarılabileceği,<br />
        - Kişisel veri toplamanın yöntemi ve hukuki sebebi,<br />
        - 11 inci maddede sayılan diğer hakları, konusunda bilgi vermekle yükümlüdür.<br /><br />
        <b>Büromuza başvurmanız halinde ise size hukuki bir yaptırım gelmemesini sağlayacak bir aydınlatma metni hazırlamamız mümkündür.</b>
        </p>
    </AccordionTab>
    <AccordionTab header="Kişisel verilerin kaydedilmesinin yaptırımı nedir?">
        <p className="m-0">
          Hukuka aykırı olarak kişisel verileri kaydetme suçunun işlenmesi halinde 1 ila 3 yıl arasında hapis cezası verilir. Kişisel verinin siyasi, veya dini görüş, ırki köken, ahlaki eğilim, cinsel yaşam, sağlık durumu veya sendikal bağlantıya (özel nitelikli kişisel veri) ilişkin olması halinde 1,5 yıl ila 4,5 yıla kadar olacak şekilde yarı oranında artırılır.<br /><br />
          <b>Büromuza başvurmanız halinde ise ticari faaliyetleriniz kapsamında, size hukuki yaptırım gelmemesini sağlayacak bir hukuki destek sağlamamız mümkündür.</b>
        </p>
    </AccordionTab>
    <AccordionTab header="Kişisel verileri saklama ve imha süreci hazırlamakla yükümlü müsünüz?">
        <p className="m-0">
        Veri Sorumluları Siciline kayıt olmakla yükümlü olan veri sorumluları, kişisel veri işleme envanterine uygun olarak kişisel veri saklama ve imha süreci hazırlamakla yükümlüdür.
        <br /><br />
          <b>Büromuza başvurmanız halinde ise size, kişisel verileri saklama ve imha süreci hazırlama konusunda bir hukuki destek sağlamamız mümkündür.</b>
        </p>
    </AccordionTab>
    {/* <AccordionTab header="Kişisel Verilerin Korunması Hukuku nedir?">
        <p className="m-0">
            Kişisel verilerin korunması hukuku, bireylerin kişisel verilerinin toplanması, işlenmesi, saklanması ve kullanılmasını düzenleyen hukuk dalıdır. Bu hukuk dalı, veri sahiplerinin verilerinin güvenliğini ve gizliliğini korumayı amaçlar.
        </p>
    </AccordionTab>
    <AccordionTab header="Kişisel veri nedir?">
        <p className="m-0">
            Kişisel veri, bir bireyin kimliğini belirli veya belirlenebilir kılan her türlü bilgiyi ifade eder. İsim, adres, telefon numarası, TC kimlik numarası gibi veriler kişisel veri kapsamına girer.
        </p>
    </AccordionTab>
    <AccordionTab header="Veri sorumlusu ve veri işleyen kimdir?">
        <p className="m-0">
            Veri sorumlusu, kişisel verilerin işlenmesini belirleyen ve kontrol eden gerçek veya tüzel kişidir. Veri işleyen ise veri sorumlusunun talimatı doğrultusunda kişisel verileri işleyen gerçek veya tüzel kişidir.
        </p>
    </AccordionTab>
    <AccordionTab header="Kişisel verilerin işlenmesi şartları nelerdir?">
        <p className="m-0">
            Kişisel verilerin işlenmesi, veri sahibinin açık rızası, hukuki yükümlülüğün yerine getirilmesi, sözleşmenin kurulması veya ifası, kamu yararının gerektirdiği durumlar gibi belirli şartlara bağlıdır.
        </p>
    </AccordionTab>
    <AccordionTab header="Kişisel veri ihlalleri nasıl bildirilir ve cezaları nelerdir?">
        <p className="m-0">
            Kişisel veri ihlalleri, veri sorumlusu tarafından derhal ilgili kişi veya kurumlara bildirilmelidir. Veri ihlalleri durumunda idari para cezaları, tazminat talepleri ve diğer cezai yaptırımlar uygulanabilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda kişisel verilerin korunması hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine kişisel veri koruma danışmanlığı, veri ihlallerine karşı hukuki destek, GDPR uyumluluğu sağlama ve kişisel veri işleme süreçlerinde hukuki rehberlik hizmetleri sunmaktadır.
        </p>
    </AccordionTab> */}
</Accordion>

          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default KisiselVerileriKorumaKanunu;
