import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/hakkimizdaHeader.jpeg";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const FikriMulkiyetHukuku = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | FİKRİ VE SINAİ MÜLKİYET HUKUKU | EKŞİOĞLU HUKUK</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.eksiogluhukukofisi.com/ekonomi-ve-ticaret-hukuku/fikri-mulkiyet-hukuku"
        />
        <meta property="og:title" content="HAKKIMIZDA | EKŞİOĞLU HUKUK" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="EKŞİOĞLU HUKUK sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="https://www.eksiogluhukukofisi.com/static/media/eksiogluWhiteLogo.e14b5e9c41e5271d670c.png" />
        <meta
          property="og:url"
          content="https://www.eksiogluhukukofisi.com/ekonomi-ve-ticaret-hukuku/fikri-mulkiyet-hukuku"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      

      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h1
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>FİKRİ VE SINAİ MÜLKİYET HUKUKU</b>
            </h1>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="Sözleşme ve Borçlar Hukuku"
                className="img rounded"
                src={require("assets/eksiogluPictures/hizmetler/isHukuku.webp")}
                height={540}
                width={540}
                loading="eager"
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>
            Fikri ve sınai mülkiyet hukuku, bireylerin ve işletmelerin yaratıcı ve yenilikçi çalışmalarını, ticari
            markalarını, patentlerini, tasarımlarını ve diğer mülkiyet haklarını koruyan bir hukuk dalıdır. Bu hukuk
            dalı, yaratıcı faaliyetleri teşvik eder ve sahiplerine bu çalışmalar üzerinde tekel hakkı tanır.
            </p>
            <br></br>
            <p>
            <b>1. Fikri Mülkiyet</b><br/>
            Telif Hakları: Edebi, sanatsal ve bilimsel eserler üzerindeki hakları ifade eder. Yazılı eserler, müzik
            eserleri, resimler, heykeller ve yazılımlar gibi yaratıcı çalışmalar telif hakkı koruması altındadır.
            Bağlantılı Haklar: Telif haklarına ek olarak, sanatçılar, yapımcılar ve yayıncılar gibi hak sahiplerine
            tanınan haklardır.
            </p>
            <br></br>
            <p>
            <b>2. Sınai Mülkiyet</b><br/>
            Markalar: Bir işletmenin mal veya hizmetlerini diğerlerinden ayırt etmeye yarayan işaretlerdir.
            Marka tescili, işletmelere markalarını koruma hakkı sağlar.<br/>
            Patentler: Yeni, buluş basamağı içeren ve sanayiye uygulanabilir buluşların korunmasını sağlar.
            Patent sahipleri, belirli bir süre boyunca buluşlarını tekel olarak kullanma hakkına sahiptir.<br/>
            
            
            </p>



            </Col>
          </Row>

          <Row>
          <Col
              className="mr-auto mt-5 mt-md-3"
              md="12"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
            <p>Tasarım Tescili: Bir ürünün tümü veya bir parçasının görünüm özelliklerinin korunmasını sağlar.
            Bu, ürünlerin estetik ve özgün tasarımlarını korur.<br/>
              Coğrafi İşaretler: Belirli bir bölge veya yöreye özgü ürünlerin korunmasını sağlar. Bu işaretler,
            ürünlerin kalitesi, ünü veya diğer özellikleri ile coğrafi kökenine dayalıdır.
            </p>
            
            <br></br>
            <p>

            <b>Koruma Süreleri</b><br/>
              Telif Hakkı Koruması: Eser sahibinin yaşamı boyunca ve ölümünden itibaren 70 yıl boyunca geçerlidir.
              Bu süre sonunda eser kamu malı olur ve herkes tarafından serbestçe kullanılabilir.<br/>
              Bağlantılı Haklar: Sanatçı, yapımcı ve yayıncıların hakları genellikle 50 yılsüreyle korunur.<br/>
              Marka Tescili Koruması: Markaların korunması için tescil edilmesi gereklidir. Marka tescili, sahibine
              markayı kullanma, devretme, lisans verme ve haksız kullanımlara karşı dava açma hakkı verir. Marka
              tescili 10 yılsüreyle geçerlidir ve 10 yıllık dönemler halinde yenilenebilir.<br/>
              Patent Tescili Koruması: Bir buluşun korunabilmesi için patent başvurusu yapılmalıdır. Patent tescili,
              sahibine buluşu kullanma, satma, devretme ve lisans verme hakkı tanır. Patentler, başvuru tarihinden
              itibaren 20 yılsüreyle korunur.<br/>
              Tasarım Tescili Koruması: Ürünlerin estetik ve özgün tasarımlarının korunması için tasarım tescili
              yapılmalıdır. Bu tescil, tasarım sahibine tasarımı kullanma ve haksız kullanımlara karşı dava açma hakkı
              verir. Tasarım tescili 5 yılsüreyle geçerlidir ve 5 yıllık dönemler halinde 25 yıla kadar yenilenebilir.
            </p>
            <br></br>
            <p>
            <b>Fikri ve Sınai Mülkiyet Haklarının İhlali</b><br/>
              Fikri ve sınai mülkiyet haklarının ihlali durumunda, hak sahipleri hukuki yollara başvurarak ihlalin
              durdurulmasını ve tazminat talep edebilirler. Ayrıca, cezai yaptırımlar da uygulanabilir.
              Korsanlık ve Sahtecilik: Telif haklarının ve ticari markaların izinsiz kullanımı korsanlık ve sahtecilik
              olarak kabul edilir ve ciddi hukuki yaptırımlar uygulanır.<br/>
              Bu bilgiler Türk Fikri ve Sınai Mülkiyet Hukuku'nun genel hatlarını oluşturmakta olup detaylı bilgiye
              ihtiyacınız varsa danışmanlık için, yardıma ihtiyacınız var ise başvuru, arabuluculuk ve dava süreçlerini
              yürütmemiz için bize ulaşabilirsiniz.
            </p>



            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
    <AccordionTab header="Fikri ve Sınai Mülkiyet Haklarının İhlali Durumunda Ne Yapılabilir?">
        <p className="m-0">
        Fikri ve sınai mülkiyet haklarının ihlali durumunda, hak sahipleri hukuki yollara başvurarak ihlalin
        durdurulmasını ve tazminat talep edebilirler. İhlal durumunda dava açmak, ihtiyati tedbir talep etmek
        veya arabuluculuk yoluna başvurmak gibiseçenekler bulunmaktadır.
        </p>
    </AccordionTab>
    <AccordionTab header="İnternetten İndirilen İçeriklerin Telif Hakkı Koruması Altında Olduğunu Nasıl Anlarım?">
        <p className="m-0">
        İnternetten indirilen içeriklerin telif hakkı koruması altında olup olmadığını anlamak için içeriğin
        yayınlandığı platformun kullanım koşullarını ve telif hakkı bildirimlerini incelemek gerekir. Genellikle
        içeriklerin izinsiz kopyalanması ve dağıtılması yasaktır. Telif hakkı sahiplerinin izni olmadan içerikleri
        kullanmak hukuki sorunlara yol açabilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Lisans Sözleşmesi Nedir?">
        <p className="m-0">
        Lisans sözleşmesi, fikri ve sınai mülkiyet haklarının belirli şartlar altında üçüncü kişilere kullanım hakkı
        verilmesini sağlayan bir anlaşmadır. Lisans sözleşmesi, hak sahibinin haklarını devretmeden, bu hakları
        başkalarının kullanımına açmasına olanak tanır. Lisans sözleşmesinde, kullanım süresi, kapsamı, bölgesi
        ve lisans bedeli gibi şartlar belirtilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Fikri ve Sınai Mülkiyet Hakları Uluslararası Düzeyde Nasıl Korunur?">
        <p className="m-0">
        Fikri ve sınai mülkiyet haklarının uluslararası düzeyde korunması için çeşitli uluslararası anlaşmalar ve
        organizasyonlar bulunmaktadır. Bunlar arasında WIPO (Dünya Fikri Mülkiyet Örgütü) ve TRIPS
        (Ticaretle Bağlantılı Fikri Mülkiyet Hakları Anlaşması) bulunmaktadır. Bu anlaşmalar, fikri mülkiyet
        haklarının uluslararası standartlarda korunmasını ve ihlal durumlarında hukuki yollara başvurulmasını
        sağlar.
        </p>
    </AccordionTab>
    <AccordionTab header="Hak ihlali durumunda arabuluculuğa başvurmak zorunlu mudur?">
        <p className="m-0">
        Fikri ve sınai mülkiyet hakları ihlali durumunda arabuluculuğun zorunlu olup olmadığı, uyuşmazlığın
        niteliğine ve ilgili mevzuata bağlıdır. Ticari nitelikteki davalar için arabuluculuk genellikle zorunludur.
        Ancak, her durumda özel hükümlere ve detaylara dikkat etmek gerekir. Bu nedenle, spesifik durumlarda
        hukuki danışmanlık almak önemlidir.
        </p>
    </AccordionTab>
    {/* <AccordionTab header="Fikri Mülkiyet Hukuku nedir?">
        <p className="m-0">
            Fikri mülkiyet hukuku, fikir ve sanat eserlerinin yaratıcılarına tanınan hakları düzenleyen hukuk dalıdır. Fikri mülkiyet hakları, patent, telif hakkı, ticari marka, endüstriyel tasarım gibi çeşitli kategorilerde değerlendirilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Fikri mülkiyet hakları nelerdir?">
        <p className="m-0">
            Fikri mülkiyet hakları, yaratıcının eserine sahip olma, eserini kullanma, yayma, çoğaltma ve ticari olarak değerlendirme gibi hakları içerir. Bu haklar, yaratıcının eserine ilişkin koruma sağlar.
        </p>
    </AccordionTab>
    <AccordionTab header="Patent nedir?">
        <p className="m-0">
            Patent, buluş sahibine buluşunu belirli bir süre boyunca kullanma ve üçüncü kişilerin bu buluşu izinsiz olarak kullanmasını engelleme hakkı veren koruma sistemidir.
        </p>
    </AccordionTab>
    <AccordionTab header="Telif hakkı nedir?">
        <p className="m-0">
            Telif hakkı, bir eserin yaratıcısına eserini üretme, yayma ve ticari olarak değerlendirme haklarını veren koruma sistemidir. Eser sahibi, eserinin izinsiz kullanılmasını önleyebilir ve bu eserden gelir elde edebilir.
        </p>
    </AccordionTab>
    <AccordionTab header="Ticari marka nedir?">
        <p className="m-0">
            Ticari marka, bir mal veya hizmetin belirli bir işletmeye ait olduğunu gösteren işaretlerdir. Marka sahibine, markasını başkalarının kullanmasını engelleme ve markasını ticari faaliyetlerde kullanma hakkı verir.
        </p>
    </AccordionTab>
    <AccordionTab header="Fikri mülkiyet hukuku ve dijital çağ ilişkisi nedir?">
        <p className="m-0">
            Dijital çağda fikri mülkiyet hakları, internet üzerinden paylaşılan içerikler, yazılımlar, dijital platformlar gibi yeni alanlarda da koruma sağlamaktadır. Dijital haklar yönetimi, bu alandaki önemli konulardan biridir.
        </p>
    </AccordionTab>
    <AccordionTab header="Ekşioğlu Hukuk Bürosu'nda fikri mülkiyet hukuku alanında nasıl hizmet verilmektedir?">
        <p className="m-0">
            Ekşioğlu Hukuk Bürosu, müvekkillerine patent başvuruları, telif hakkı koruma, ticari marka tescili, fikri mülkiyet ihlalleri ve bu konularla ilgili hukuki danışmanlık hizmetleri sunmaktadır.
        </p>
    </AccordionTab> */}
</Accordion>



          </div>
          <div className="team-2 pb-0 pt-0">
            <DynamicCardSection />
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default FikriMulkiyetHukuku;
