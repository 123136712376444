import React, { useEffect } from "react";
import Headers from "../components/headers/Headers";
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar";
import Footer from "components/Footers/Footer";
import { Helmet } from "react-helmet-async";
import NedenBiz from "components/NedenBiz/NedenBiz";
import GoogleReviews from "components/GoogleReviews/GoogleReviews";
import DynamicCardSection from "components/Hizmetlerimiz/DynamicCardSection";

const Home = () => {
  useEffect(() => {
    document.body.classList.add("sections-page", "sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    
    const href = window.location.href.split("#/" ).pop();
    const hrefId = href.split("#").pop();
    if (href.includes("#")) {
      document.getElementById(hrefId)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
    
    return () => {
      document.body.classList.remove("sections-page", "sidebar-collapse");
    };
  }, []);

  useEffect(() => {
    const targetLink = document.querySelector('a[data-gt-lang="tr"]');
    
    if (targetLink) {
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      if (imgElement) {
        imgElement.src = "https://cdn.gtranslate.net/flags/32/tr.png";
        imgElement.width = 32;
        imgElement.height = 32;
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Ekşioğlu Hukuk | Anasayfa</title>
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Ekşioğlu Hukuk, müvekkillerinin haklarını mesleki etik değerlerine bağlı kalarak korumayı hedefleyen uluslararası vizyona sahip bir avukatlık bürosudur." />
        <meta name="keywords" content="Ekşioğlu Hukuk, avukatlık, hukuk danışmanlığı, Türkiye hukuk, avukat" />
        <meta name="author" content="Ekşioğlu Hukuk" />
        <link rel="canonical" href="https://www.eksiogluhukukofisi.com" />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Ekşioğlu Hukuk | Anasayfa" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Hukuk danışmanlığı ve avukatlık hizmetleri sunan bir firma." />
        <meta property="og:image" content="https://www.eksiogluhukukofisi.com/static/media/eksiogluWhiteLogo.e14b5e9c41e5271d670c.png" />
        <meta property="og:url" content="https://www.eksiogluhukofisi.com" />
        <meta property="og:site_name" content="Ekşioğlu Hukuk" />
        
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="https://www.eksiogluhukukofisi.com/static/media/eksiogluWhiteLogo.e14b5e9c41e5271d670c.png" />
        <meta name="twitter:title" content="Ekşioğlu Hukuk | Anasayfa" />
        <meta name="twitter:description" content="Hukuk danışmanlığı ve avukatlık hizmetleri sunan bir firma." />
        <meta name="twitter:image" content="https://www.eksiogluhukukofisi.com/static/media/eksiogluWhiteLogo.e14b5e9c41e5271d670c.png" />
        
        {/* Schema.org Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "LegalService",
            "name": "Ekşioğlu Hukuk",
            "url": "https://www.eksiogluhukofisi.com",
            "logo": "https://www.eksiogluhukukofisi.com/assets/eksiogluPictures/logo-small.png",
            "sameAs": [
              "https://www.facebook.com/eksiogluhukukofisi",
              "https://twitter.com/eksiogluhukukofisi"
            ],
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "0 850 460 95 03",
              "contactType": "Customer Service"
            }
          })}
        </script>
      </Helmet>
      
      <ScrollTransparentNavbar />
      
      <div className="wrapper">
        <Headers />
        <div className="team-2 pb-0 pt-0">
          <DynamicCardSection />
        </div>
        
        <NedenBiz />
        <GoogleReviews />
        <Footer />
      </div>
    </>
  );
};

export default Home;
